import React, { useEffect, useContext, FunctionComponent } from 'react'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'

import Scene from '../../component/Scene'

import Color from '../../model/Color'
import Storage from '../../model/Storage'

import { Store, StoreContext } from '../../store/Store'

import * as Nav from '../Nav'

const Content = styled.div`
  margin: 2rem;
`

const Table = styled.table`
  width: 100%;
  margin-top: 1rem;
`

const Row = styled.th`
  text-align: left;
`

const PhoneModel: FunctionComponent = () => {
  const store = useContext(StoreContext) as Store
  
  const history = useHistory()

  useEffect(() => {
    const load = () => {
      store.model.all()
    }

    load()
  }, [store.model])

  const color = (color?: Color[]) => color?.map((color) => color.name)?.join(', ')

  const storage = (storage?: Storage[]) => storage?.map((storage) => `${storage.size} ${storage.unit}`)?.join(', ')

  return (
    <Scene>
      <Content>
        <Button variant="contained" onClick={() => history.push(Nav.PHONE_MODEL_CREATE)}>Adicionar</Button>
        <Table>
          <thead>
            <tr>
              <Row>Key</Row>
              <Row>Name</Row>
              <Row>Color</Row>
              <Row>Storage</Row>
              <Row>Brand</Row>
            </tr>
          </thead>
          <tbody>
            {store.model.list?.map((model => (
              <tr key={model.id}>
                <td>{model.key}</td>
                <td>{model.name}</td>
                <td>{color(model.color)}</td>
                <td>{storage(model.storage)}</td>
                <td>{model.brand?.name}</td>
              </tr>
            )))}
          </tbody>
        </Table>
      </Content>
    </Scene>
  )
}

export default PhoneModel
