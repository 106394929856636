import Ax from 'axios'

import store from '../store/Store'

import { PATH } from '../Env'

const Request = Ax.create({
  baseURL: PATH,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

Request.interceptors.request.use((request) => {
  const { token } = store.auth

  request.headers['Authorization'] = `Bearer ${token?.hash}`

  return request
})

export default Request
