import React, { FunctionComponent } from 'react'
import { css, Global, ThemeProvider } from '@emotion/react'

import theme from './Theme'

import store, { StoreProvider } from './store/Store'

import Router from './scene/Router'

const Style = () => (
  <Global styles={css`
    html, body {
      height: 100%;
    }
    #root {
      height: 100%;
    }
  `} />
)

export const App: FunctionComponent = () => {

  return (
    <StoreProvider value={store}>
      <ThemeProvider theme={theme}>
        <Style />
        <Router />
      </ThemeProvider>
    </StoreProvider>
  )
}

export default App
