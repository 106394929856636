import React, { useState, useContext, FunctionComponent } from 'react'
import { Button, TextField } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'

import { Store, StoreContext } from '../../store/Store'

import * as Nav from '../Nav'

const Scene = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 22rem;
`

const Account: FunctionComponent = () => {
  const store = useContext(StoreContext) as Store
  
  const history = useHistory()

  const [name, setName] = useState<string>()
  const [mail, setMail] = useState<string>()
  const [pass, setPass] = useState<string>()

  const onCreate = () => {
    store.user.create({ name, mail, pass })
      .then(() => history.push(Nav.LOGIN))
      .catch(() => alert('Problema ao criar o user'))
  }

  return (
    <Scene>
      <Content>
        <TextField label="Name" value={name} onChange={(event) => setName(event.target.value)} />
        <TextField label="Mail" value={mail} onChange={(event) => setMail(event.target.value)} />
        <TextField label="Password" type="password" value={pass} onChange={(event) => setPass(event.target.value)} />
        <Button variant="contained" onClick={onCreate}>Create</Button>
      </Content>
    </Scene>
  )
}

export default Account
