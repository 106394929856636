import React, { useState, useContext, FunctionComponent } from 'react'
import { Button, Select, MenuItem, InputLabel, TextField, FormControl } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'

import Scene from '../../component/Scene'

import { Store, StoreContext } from '../../store/Store'

import * as Nav from '../Nav'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 40rem;
  margin-top: 4rem;
  margin-left: 6rem;
`

const PhoneModelCreate: FunctionComponent = () => {
  const store = useContext(StoreContext) as Store

  const history = useHistory()

  const [name, setName] = useState<string>()
  const [brand, setBrand] = useState<number>(1)
  const [color, setColor] = useState<number[]>([])
  const [storage, setStorage] = useState<number[]>([])

  const onBrand = (event: any) => setBrand(event.target.value)

  const onColor = (event: any) => setColor(event.target.value)

  const onStorage = (event: any) => setStorage(event.target.value)

  const onCreate = () => {
    const model = {
      name: name,
      brand: { id: brand },
      color: color.map((id) => ({ id })),
      storage: storage.map((id) => ({ id }))
    }

    store.model.create(model)
      .then(() => history.push(Nav.PHONE_MODEL))
      .catch((e) => console.log(e))
  }

  return (
    <Scene>
      <Content>
        <TextField label="name" value={name} onChange={(event) => setName(event.target.value)} />
        <FormControl>
          <InputLabel id="brand">Brand</InputLabel>
          <Select
            labelId="brand"
            value={brand}
            onChange={onBrand}>
              {store.brand.list?.map(({ id, name }) => (
                <MenuItem key={id} value={id}>{name}</MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="color">Color</InputLabel>
          <Select
            labelId="color"
            multiple
            value={color}
            onChange={onColor}>
              {store.color.list?.map(({ id, name }) => (
                <MenuItem key={id} value={id}>{name}</MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="storage">Storage</InputLabel>
          <Select
            labelId="storage"
            multiple
            value={storage}
            onChange={onStorage}>
              {store.storage.list?.map(({ id, size, unit }) => (
                <MenuItem key={id} value={id}>{`${size} ${unit}`}</MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button variant="contained" onClick={onCreate}>Create</Button>
      </Content>
    </Scene>
  )
}

export default PhoneModelCreate
