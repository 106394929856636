import React, { useEffect, useContext, FunctionComponent } from 'react'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'

import Scene from '../../component/Scene'

import { Store, StoreContext } from '../../store/Store'

import * as Nav from '../Nav'

const Content = styled.div`
  margin: 2rem;
`

const Table = styled.table`
  width: 100%;
  margin-top: 1rem;
`

const Row = styled.th`
  text-align: left;
`

const PhoneDetail: FunctionComponent = () => {
  const store = useContext(StoreContext) as Store
  
  const history = useHistory()

  useEffect(() => {
    const load = () => {
      store.detail.all()
    }

    load()
  }, [store.detail])

  return (
    <Scene>
      <Content>
        <Button variant="contained" onClick={() => history.push(Nav.PHONE_DETAIL_CREATE)}>Adicionar</Button>
        <Table>
          <thead>
            <tr>
              <Row>Model</Row>
              <Row>Brand</Row>
            </tr>
          </thead>
          <tbody>
            {store.detail.list?.map((detail => (
              <tr key={detail.id}>
                <td>{detail.model?.name}</td>
                <td>{detail.model?.brand?.name}</td>
              </tr>
            )))}
          </tbody>
        </Table>
      </Content>
    </Scene>
  )
}

export default PhoneDetail
