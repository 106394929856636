import { action, configure, observable, runInAction } from 'mobx'

import PhoneDetail from '../model/PhoneDetail'

import * as PhoneDetailRequest from '../request/PhoneDetailRequest'

configure({ enforceActions: 'always' })

export default class PhoneDetailStore {
  @observable list?: PhoneDetail[]

  @action
  all = async () => {
    const list = await PhoneDetailRequest.all().then((response) => response.data)

    runInAction(() => this.list = list)
  }

  @action
  create = async (detail: PhoneDetail) => {
    const list = await PhoneDetailRequest.create(detail).then((response) => response.data)
    
    runInAction(() => this.list = list)
  }
}
