import { action, configure, observable, runInAction } from 'mobx'

import Brand from '../model/Brand'

import * as BrandRequest from '../request/BrandRequest'

configure({ enforceActions: 'always' })

export default class BrandStore {
  @observable list?: [Brand]

  @action
  all = async () => {
    const list = await BrandRequest.all().then((response) => response.data)

    runInAction(() => this.list = list)
  }
}
