import { action, configure, observable, runInAction } from 'mobx'

import Color from '../model/Color'

import * as ColorRequest from '../request/ColorRequest'

configure({ enforceActions: 'always' })

export default class ColorStore {
  @observable list?: [Color]

  @action
  all = async () => {
    const list = await ColorRequest.all().then((response) => response.data)

    runInAction(() => this.list = list)
  }
}
