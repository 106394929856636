import React, { useState, useContext, FunctionComponent } from 'react'
import { Button as Btn, TextField } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'

import { Store, StoreContext } from '../../store/Store'

import * as Nav from '../Nav'

const Scene = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 22rem;
`

const PassInput = styled(TextField)``

const Button = styled(Btn)`
  height: 3.5rem;
`

const INITIAL_VALUE = ""

const Login: FunctionComponent = () => {
  const store = useContext(StoreContext) as Store

  const history = useHistory()

  const [mail, setMail] = useState<string>(INITIAL_VALUE)
  const [pass, setPass] = useState<string>(INITIAL_VALUE)

  const load = () => {
    Promise.all([
      store.brand.all(),
      store.color.all(),
      store.storage.all(),
      store.model.all(),
      store.detail.all()
    ])
    .then(() => history.push(Nav.HOME))
  }

  const login = () => {
    store.auth.auth(mail, pass)
      .then(() => load())
      .catch((e) => alert('login incorreto'))
  }

  return (
    <Scene>
      <Content>
        <TextField label="Mail" value={mail} onChange={(event) => setMail(event.target.value)} />
        <PassInput label="Senha" type="password" value={pass} onChange={(event) => setPass(event.target.value)} />
        <Button variant="contained" onClick={login}>Entrar</Button>
      </Content>
    </Scene>
  )
}

export default Login
