import { action, configure, observable, runInAction } from 'mobx'

import Token from '../model/Token'

import * as AuthRequest from '../request/AuthRequest'

configure({ enforceActions: 'always' })

export default class AuthStore {
  @observable token?: Token

  @action
  auth = async (mail: string, pass: string) => {
    const token = await AuthRequest.auth(mail, pass).then((response) => response.data)

    runInAction(() => this.token = token)
  }
}
