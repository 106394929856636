import { action, configure, observable, runInAction } from 'mobx'

import  Storage from '../model/Storage'

import * as StorageRequest from '../request/StorageRequest'

configure({ enforceActions: 'always' })

export default class StorageStore {
  @observable list?: Storage[]

  @action
  all = async () => {
    const list = await StorageRequest.all().then((response) => response.data)

    runInAction(() => this.list = list)
  }
}
