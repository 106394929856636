import { action, configure, observable, runInAction } from 'mobx'

import User from '../model/User'

import * as UserRequest from '../request/UserRequest'

configure({ enforceActions: 'always' })

export default class UserStore {
  @observable user?: User

  @action
  create = async (create: User) => {
    const user = await UserRequest.create(create).then((response) => response.data)

    runInAction(() => this.user = user)
  }
}
