import React, { FunctionComponent } from 'react'
import { List, ListItem, Drawer, ListItemText, ListItemIcon } from '@material-ui/core'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'

import Option from '../../model/Option'

import { Home as Icon, Star, Smartphone as Phone } from '@material-ui/icons'

interface IMenu {
  active: boolean
  onItem: (option: Option) => void
}

const option: Option[] = [
  { name: 'Início', icon: <Icon />, path: '/home' },
  { name: 'Modelo', icon: <Phone />, path: '/product/phone/model' },
  { name: 'Detalhe', icon: <Star />, path: '/product/phone/detail' }
]

const Content = styled.div`
  width: 16rem;
`

export const Menu: FunctionComponent<IMenu> = (props) => {
  const { active, onItem } = props

  return (
    <Drawer anchor="left" open={active}>
      <Content>
        <List>
          {option.map((option) => (
            <ListItem button key={option.name} component={Link} to={option.path} onClick={() => onItem(option)}>
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText>{option.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Content>
    </Drawer>
  )
}

export default Menu
