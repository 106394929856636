import React, { useState, FunctionComponent } from 'react'
import theme from '../../Theme'
import styled from '@emotion/styled'

import Menu from '../Menu'
import Option from '../../model/Option'

import { Menu as Icon } from '@material-ui/icons'

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 4rem;
  padding: 1rem;
  box-sizing: border-box;
  background: ${theme.color.primary};
`

export const Header: FunctionComponent = () => {
  const [active, setActive] = useState<boolean>(false)

  const onItem = (option: Option) => {
    setActive(false)
  }

  return (
    <Content>
      <Icon style={{ color: 'white' }} onClick={() => setActive(true)} />
      <Menu active={active} onItem={onItem} />
    </Content>
  )
}

export default Header
