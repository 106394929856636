import Ax from 'axios'

import { PATH } from '../Env'

const Request = Ax.create({
  baseURL: PATH,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export const auth = (mail: string, pass: string) => Request.post('v1/auth/mail', { mail, pass })
