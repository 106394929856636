// Account
export const LOGIN = '/login'

// Home
export const HOME = '/home'

// Phone Model
export const PHONE_MODEL = '/product/phone/model'
export const PHONE_MODEL_CREATE = '/product/phone/model/create'

// Phone Detail
export const PHONE_DETAIL = '/product/phone/detail'
export const PHONE_DETAIL_CREATE = '/product/phone/detail/create'
