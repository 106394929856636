type Theme = {
  color: {
    primary: string
    secondary: string
  }
}

const theme: Theme = {
  color: {
    primary: '#365D95',
    secondary: '#F6DD3E'
  }
}

export default theme
