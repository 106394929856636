import React, { FunctionComponent } from 'react'
import { Route, Switch, BrowserRouter } from "react-router-dom"

// Auth
import Login from '../Login'
import Account from '../Account'

// Home
import Home from '../Home'

// Phone Model
import PhoneModel from '../PhoneModel'
import PhoneModelCreate from '../PhoneModelCreate'

// Phone Detail
import PhoneDetail from '../PhoneDetail'
import PhoneDetailCreate from '../PhoneDetailCreate'

const Router: FunctionComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/login'><Login /></Route>
        <Route path='/account/create'><Account /></Route>

        <Route path='/home'><Home /></Route>

        <Route path='/product/phone/model/create'><PhoneModelCreate /></Route>
        <Route path='/product/phone/model'><PhoneModel /></Route>

        <Route path='/product/phone/detail/create'><PhoneDetailCreate /></Route>
        <Route path='/product/phone/detail'><PhoneDetail /></Route>
        
        <Route path='/'><Login /></Route>
      </Switch>
    </BrowserRouter>
  )
}

export default Router
