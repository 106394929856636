import React, { FunctionComponent } from 'react'
import styled from '@emotion/styled'

import Scene from '../../component/Scene'

const Content = styled.div``

const Home: FunctionComponent = () => {
  return (
    <Scene>
      <Content>
      </Content>
    </Scene>
  )
}

export default Home
