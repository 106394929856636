import React, { useState, useContext, FunctionComponent } from 'react'
import { Button, Select, MenuItem, InputLabel, TextField, FormControl } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import styled from '@emotion/styled'

import Scene from '../../component/Scene'

import Content from '../../model/Content'

import { Store, StoreContext } from '../../store/Store'

import * as Nav from '../Nav'

const Form = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 40rem;
  margin-top: 4rem;
  margin-left: 6rem;
`

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`

const CONTENT = [new Content(), new Content(), new Content(), new Content()]

export const PhoneDetailCreate: FunctionComponent = () => {
  const store = useContext(StoreContext) as Store

  const history = useHistory()

  const [description, setDescription] = useState<string>()
  const [model, setModel] = useState<number>(1)
  const [content] = useState<Content[]>(CONTENT)

  const setName = (index: number, event: any) => content[index].name = event.target.value

  const setValue = (index: number, event: any) => content[index].content = event.target.value

  const onModel = (event: any) => setModel(event.target.value)

  const onCreate = () => {
    const detail = {
      description: description,
      model: { id: model },
      content: content.map(({ name, content }) => ({ name, content }))
    }

    store.detail.create(detail)
      .then(() => history.push(Nav.PHONE_DETAIL))
      .catch((e) => console.log(e))
  }

  return (
    <Scene>
      <Form>
        <TextField label="Description" value={description} onChange={(event) => setDescription(event.target.value)} />
        <FormControl>
          <InputLabel id="model">Model</InputLabel>
          <Select
            labelId="model"
            value={model}
            onChange={onModel}>
              {store.model.list?.map(({ id, name }) => (
                <MenuItem key={id} value={id}>{name}</MenuItem>
              ))}
          </Select>
        </FormControl>
        {content.map((content, index) => (
          <Detail>
            <TextField label={`Name ${index + 1}`} value={content.name} onChange={(event) => setName(index, event)} />
            <TextField label={`Content ${index + 1}`} value={content.content} onChange={(event) => setValue(index, event)} />
          </Detail>
        ))}
        <Button variant="contained" onClick={onCreate}>Create</Button>
      </Form>
    </Scene>
  )
}

export default PhoneDetailCreate
