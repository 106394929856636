import { createContext } from 'react'

import AuthStore from './AuthStore'

import UserStore from './UserStore'

// Product
import BrandStore from './BrandStore'
import ColorStore from './ColorStore'
import StorageStore from './StorageStore'

// Phone
import PhoneModelStore from './PhoneModelStore'
import PhoneDetailStore from './PhoneDetailStore'

// Auth
const auth = new AuthStore()

// User
const user = new UserStore()

// Product
const brand = new BrandStore()
const color = new ColorStore()
const storage = new StorageStore()

// Phone
const model = new PhoneModelStore()
const detail = new PhoneDetailStore()

export type Store = {
  auth: AuthStore
  user: UserStore
  brand: BrandStore
  color: ColorStore
  storage: StorageStore
  model: PhoneModelStore
  detail: PhoneDetailStore
}

export const StoreContext = createContext({
  
})

export const StoreProvider = StoreContext.Provider

const store: Store = {
  auth,
  user,
  brand,
  color,
  storage,
  model,
  detail
}

export default store
