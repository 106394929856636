import React, { FunctionComponent } from 'react'
import styled from '@emotion/styled'

import Header from '../Header'

const Content = styled.div``

export const Scene: FunctionComponent = (props) => {
  const { children } = props

  return (
    <Content>
      <Header />
      {children}
    </Content>
  )
}

export default Scene
