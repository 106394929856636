import { action, configure, observable, runInAction } from 'mobx'

import PhoneModel from '../model/PhoneModel'

import * as PhoneModelRequest from '../request/PhoneModelRequest'

configure({ enforceActions: 'always' })

export default class PhoneModelStore {
  @observable list?: PhoneModel[]

  @action
  all = async () => {
    const list = await PhoneModelRequest.all().then((response) => response.data)

    runInAction(() => this.list = list)
  }

  @action
  create = async (model: PhoneModel) => {
    const list = await PhoneModelRequest.create(model).then((response) => response.data)
    
    runInAction(() => this.list = list)
  }
}
